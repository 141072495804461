<template>
    <div class="FooterButton">
        <div v-ripple class="btn" :class="[buttonFunction]"
             :locale-back="$t('footerButton.back')"
             :locale-cancel="$t('footerButton.cancel')"
             :locale-createBackup="$t('footerButton.createBackup')"
             :locale-delete="$t('footerButton.delete')"
             :locale-edit="$t('footerButton.edit')"
             :locale-export="$t('footerButton.export')"
             :locale-import="$t('footerButton.import')"
             :locale-new="$t('footerButton.new')"
             :locale-newDisc="$t('footerButton.newDisc')"
             :locale-ok="$t('footerButton.ok')"
             :locale-open="$t('footerButton.open')"
             :locale-pause="$t('footerButton.pause')"
             :locale-print="$t('footerButton.print')"
             :locale-printAll="$t('footerButton.printAll')"
             :locale-productTest="$t('footerButton.productTest')"
             :locale-quick="$t('footerButton.quickTest')"
             :locale-report="$t('footerButton.report')"
             :locale-reportWaiting="$t('footerButton.reportWaiting')"
             :locale-reports="$t('footerButton.reports')"
             :locale-restoreBackup="$t('footerButton.restoreBackup')"
             :locale-resume="$t('footerButton.resume')"
             :locale-save="$t('footerButton.save')"
             :locale-start="$t('footerButton.start')"
             :locale-stop="$t('footerButton.stop')"
             :locale-update="$t('footerButton.update')"
             :locale-mount="$t('footerButton.mount')"
             :locale-unmount="$t('footerButton.unmount')"
             @click="footerButtonClick">
            <div
                v-show="(buttonFunction == 'save' || buttonFunction == 'export' || buttonFunction == 'print' || buttonFunction == 'printAll' || buttonFunction == 'delete' || buttonFunction == 'update' || buttonFunction == 'createBackup' || buttonFunction == 'restoreBackup' || buttonFunction == 'mount' || buttonFunction == 'unmount') && progress"
                class="progress">
                <v-progress-circular :size="25" :width="3" color="white" indeterminate class=""></v-progress-circular>
            </div>
            <div
                v-show="(buttonFunction == 'save' || buttonFunction == 'export' || buttonFunction == 'print' || buttonFunction == 'printAll' || buttonFunction == 'delete' || buttonFunction == 'update' || buttonFunction == 'createBackup' || buttonFunction == 'restoreBackup' || buttonFunction == 'mount' || buttonFunction == 'unmount') && successCheck"
                class="successCheck">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterButton",
    props: {
        buttonFunction: {
            type: String,
            default: 'empty'
        },
        progress: {
            type: Boolean,
            default: false
        },
        successCheck: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        /**
         * When Button is clicked, event is send to parent view
         */
        footerButtonClick() {
            this.$emit('footerButtonClick');
        }
    }
}
</script>

<style scoped lang="scss">
.FooterButton {
    flex: 1 1 0;
    margin-right: 10px;
    position: relative;

    &:last-child {
        margin-right: 0;
    }

    &.disabled {
        pointer-events: none;

        .btn {
            background-color: $disiLightIrisblue;
            transition: all 0.2s linear;
        }
    }
}

.progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $disiIrisblue;
    transition: all 0.2s linear;
}

.successCheck {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: white;
    background-color: $disiIrisblue;
    transition: all 0.2s linear;

    &::before {
        content: "";
        background-image: url("../assets/OK_weiss.svg");
        height: 22px;
        width: 22px;
        margin-bottom: 2px;
        background-size: contain;
        background-position: center;
    }
}

.v-btn, .btn {
    max-width: none;
    height: 60px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $disiDarkblue;
    text-transform: uppercase;
    color: white;
    transition: all 0.2s linear;

    &:active {
        background-color: $disiDarkblue;
    }

    &::after {
        content: "";
        padding-top: 3px;
        font-weight: 500;
        font-size: 0.85rem;
        line-height: 1rem;
        text-transform: uppercase;
        color: white;
    }

    &::before {
        content: "";
        width: 17px;
        height: 17px;
        margin-right: 10px;
        margin-top: 2px;
        background-size: contain;
        background-position: center;
    }

    &.empty {
        cursor: unset;
        background-color: $disiLightIrisblue;
    }

    &.back {
        &::before {
            background-image: url("../assets/back_weiss.svg");
            margin-bottom: 1px;
            height: 23px;
            width: 23px;
        }

        &::after {
            content: attr(locale-back);
        }
    }

    &.cancel {
        &::before {
            background-image: url("../assets/cancel_weiss.svg");
            margin-bottom: 1px;
        }

        &::after {
            content: attr(locale-cancel);
        }
    }

    &.createBackup {
        &::before {
            background-image: url("../assets/save-backup_weiss.svg");
            height: 24px;
            width: 24px;
        }

        &::after {
            content: attr(locale-createBackup);
            max-width: 50%;
        }
    }

    &.delete {
        &::before {
            background-image: url("../assets/delete_weiss.svg");
            height: 20px;
            width: 20px;
        }

        &::after {
            content: attr(locale-delete);
        }
    }

    &.export {
        &::before {
            height: 23px;
            width: 23px;
            background-image: url("../assets/export_weiss.svg");
        }

        &::after {
            content: attr(locale-export);
        }
    }

    &.import {
        &::before {
            height: 23px;
            width: 23px;
            background-image: url("../assets/import_weiss.svg");
        }

        &::after {
            content: attr(locale-import);
        }
    }

    &.mount {
        &::before {
            height: 23px;
            width: 23px;
            background-image: url("../assets/logout_weiss.svg");
        }

        &::after {
            content: attr(locale-mount);
        }
    }

    &.unmount {
        &::before {
            height: 23px;
            width: 23px;
            background-image: url("../assets/logout_weiss.svg");
        }

        &::after {
            content: attr(locale-unmount);
        }
    }


    &.new {
        &::before {
            background-image: url("../assets/new_weiss.svg");
            height: 20px;
            width: 20px;
            margin-bottom: 2px;
        }

        &::after {
            content: attr(locale-new);
        }
    }

    &.newDisc {
        &::before {
            background-image: url("../assets/new_weiss.svg");
            height: 20px;
            width: 20px;
            margin-bottom: 2px;
        }

        &::after {
            content: attr(locale-newDisc);
        }
    }

    &.ok {
        &::before {
            background-image: url("../assets/OK_weiss.svg");
            height: 22px;
            width: 22px;
        }

        &::after {
            content: attr(locale-ok);
        }
    }

    &.open {
        &::before {
            background-image: url("../assets/view_weiss.svg");
            height: 22px;
            width: 22px;
        }

        &::after {
            content: attr(locale-open);
        }
    }

    &.quickTest {
        &::before {
            background-image: url("../assets/quickTest_weiss.svg");
            height: 19px;
            width: 19px;
        }

        &::after {
            content: attr(locale-quick);
        }
    }

    &.pause {
        &::before {
            background-image: url("../assets/pause_weiss.svg");
            height: 20px;
            width: 20px;
        }

        &::after {
            content: attr(locale-pause);
        }
    }

    &.print {
        &::before {
            background-image: url("../assets/print_weiss.svg");
            height: 23px;
            width: 23px;
        }

        &::after {
            content: attr(locale-print);
        }
    }

    &.printAll {
        &::before {
            background-image: url("../assets/print_weiss.svg");
            height: 23px;
            width: 23px;
        }

        &::after {
            content: attr(locale-printAll);
        }
    }

    &.productTest {
        &::before {
            background-image: url("../assets/productTest_weiss.svg");
            height: 32px;
            width: 32px;
        }

        &::after {
            max-width: 85px;
            content: attr(locale-productTest);
        }
    }

    &.report {
        &::before {
            background-image: url("../assets/report_weiss.svg");
            height: 24px;
            width: 24px;
        }

        &::after {
            content: attr(locale-report);
        }
    }

    &.reportWaiting {
        background-color: $disiDarkblueDisabled;

        &::before {
            background-image: url("../assets/report_weiss.svg");
            height: 24px;
            width: 24px;
        }

        &::after {
            content: attr(locale-report);
        }
    }

    &.reports {
        &::before {
            background-image: url("../assets/report_weiss.svg");
            height: 24px;
            width: 24px;
        }

        &::after {
            content: attr(locale-reports);
        }
    }

    &.restoreBackup {
        &::before {
            background-image: url("../assets/backup-restore_weiss.svg");
            height: 24px;
            width: 24px;
        }

        &::after {
            content: attr(locale-restoreBackup);
            max-width: 50%;
        }
    }

    &.resume {
        &::before {
            background-image: url("../assets/start_weiss.svg");
            height: 21px;
            width: 21px;
        }

        &::after {
            content: attr(locale-resume);
        }
    }

    &.save {
        &::before {
            background-image: url("../assets/save_weiss.svg");
            height: 19px;
            width: 19px;
        }

        &::after {
            content: attr(locale-save);
        }
    }

    &.start {
        &::before {
            background-image: url("../assets/start_weiss.svg");
            height: 21px;
            width: 21px;
        }

        &::after {
            content: attr(locale-start);
        }
    }

    &.stop {
        &::before {
            background-image: url("../assets/pause_weiss.svg");
            height: 20px;
            width: 20px;
        }

        &::after {
            content: attr(locale-stop);
        }
    }

    &.update {
        &::before {
            background-image: url("../assets/start_weiss.svg");
            height: 21px;
            width: 21px;
        }

        &::after {
            content: attr(locale-update);
        }
    }
}

@keyframes showAni {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes hideAni {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
</style>